import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useFormik, FieldArray } from 'formik'
import { getPhotos, Photo, createPhotos } from '../../requests/photos'
import { UploadZone } from '../../../components/UploadZone'
import { handleError } from '../../errors/handleError'
import { AdminContainer } from '../../components/AdminContainer'
import './styles.css'

type Props = {
}

export const Photos: React.FC<Props> = () => {
    const formik = useFormik({
        initialValues: {
            photos: []
        } as { photos: Photo[] },
        onSubmit: async (values) => {
            try {
                await createPhotos(values.photos)
                toast.success('Photos saved!')
            } catch (err) {
                handleError(err, 'Whoops, something went wrong saving your photos. Please try again later.')
            }
            formik.resetForm()
        },
        onReset: () => {
            getPhotos().then((res) => {
                formik.setFieldValue('photos', res.data)
            })
        }
    })

    useEffect(() => {
        getPhotos().then((res) => {
            formik.setFieldValue('photos', res.data)
        })
    }, [])

    return (
        <AdminContainer title="Photos" formik={formik} onSubmit={formik.handleSubmit}>
                <FieldArray name="photos">
                    {({ push, remove }) => (
                        <>
                            <UploadZone
                                label="Add New Photo"
                                name="newPhoto"
                                onFileUploaded={(url) => push({ url, title: 'Untitled' })}
                            />
                            <div className="photos-container">
                                {
                                    !formik.values.photos?.length && (
                                        <p>No photos to show.</p>
                                    )
                                }
                                {formik.values.photos.map((photo, index) => (
                                    <div key={photo.url} className="photo">
                                        <div
                                            className="image-preview"
                                            style={{ backgroundImage: `url(${photo.url})`}}
                                        />
                                        <label htmlFor={`photos.${index}.title`}>
                                            Title
                                            <input
                                                type="text"
                                                value={photo.title}
                                                name={`photos.${index}.title`}
                                                onChange={formik.handleChange}
                                                placeholder="Cabin, Wilmington NC"
                                            />
                                        </label>
                                        <button type="button" onClick={() => remove(index)}>Remove</button>
                                    </div>
                                ))}
                                <div className="photo">
                                </div>
                            </div>
                        </>
                    )}
                </FieldArray>
        </AdminContainer>
    )
}
