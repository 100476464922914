import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createRoot } from "react-dom/client"
// import { App } from './App'
import { Routes } from './Routes'
import './App.css'

const node = document.getElementById("app")

if (!node) {
    throw Error(`No #app in document.`)
}

const root = createRoot(node)
root.render(
    <>
        <ToastContainer position="top-center" />
        <Routes />
    </>
)
