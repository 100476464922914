import React, { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { Outlet, NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSession } from '../requests/session'
import './App.css'

export const AppLayout: React.FC = () => {
    const [navOpen, setNavOpen] = useState(false)
    const [loading, setLoading] = useState(true)

    const logout = () => {
        toast.error('Logging you out...', { autoClose: 2000 })
        setTimeout(() => {
            localStorage.clear()
            window.location.href = "/"
        }, 2000)
    }

    useEffect(() => {
        const checkAuth = async () => {
            try {
                await getSession()
                setLoading(false)
            } catch (err) {
                if ((err as AxiosError).response.status === 400) {
                    toast.error('Your session has expired. Logging you out...')
                    setTimeout(() => logout(), 2000)
                }
            }
        }

        checkAuth()
        window.addEventListener('focus', checkAuth)

        return () => {
            window.removeEventListener('focus', checkAuth)
        }
    }, [])

    const toggleNav = () => {
        setNavOpen(!navOpen)
    }

    if (loading) {
        return (
            <div className="app-layout">
                <header>
                    <button className="mobile-toggle-nav" onClick={toggleNav}>
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M4.25 24.0833H29.75M4.25 17H29.75M4.25 9.91663H29.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    </button>
                    <img src="/assets/logo.png" height={40} />
                    { /* <span>Outpost Builders</span> */ }
                    <button onClick={logout}>
                        Log Out
                    </button>
                </header>
            </div>
        )
    }

    return (
        <div className="app-layout">
            <header>
                <button className="mobile-toggle-nav" onClick={toggleNav}>
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M4.25 24.0833H29.75M4.25 17H29.75M4.25 9.91663H29.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </button>
                <img src="/assets/logo.png" height={40} />
                { /* <span>Outpost Builders</span> */ }
                <button onClick={logout}>
                    Log Out
                </button>
            </header>
            <nav className={navOpen ? 'mobile-nav-open' : ''}>
                <NavLink to="/app/dashboard/photos">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M216,40H72A16,16,0,0,0,56,56V72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V184h16a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM72,56H216v62.75l-10.07-10.06a16,16,0,0,0-22.63,0l-20,20-44-44a16,16,0,0,0-22.62,0L72,109.37ZM184,200H40V88H56v80a16,16,0,0,0,16,16H184Zm32-32H72V132l36-36,49.66,49.66a8,8,0,0,0,11.31,0L194.63,120,216,141.38V168ZM160,84a12,12,0,1,1,12,12A12,12,0,0,1,160,84Z"></path></svg>
                    <span>Photos</span>
                </NavLink>
                <NavLink to="/app/dashboard/testimonials">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M117.25,157.92a60,60,0,1,0-66.5,0A95.83,95.83,0,0,0,3.53,195.63a8,8,0,1,0,13.4,8.74,80,80,0,0,1,134.14,0,8,8,0,0,0,13.4-8.74A95.83,95.83,0,0,0,117.25,157.92ZM40,108a44,44,0,1,1,44,44A44.05,44.05,0,0,1,40,108Zm210.14,98.7a8,8,0,0,1-11.07-2.33A79.83,79.83,0,0,0,172,168a8,8,0,0,1,0-16,44,44,0,1,0-16.34-84.87,8,8,0,1,1-5.94-14.85,60,60,0,0,1,55.53,105.64,95.83,95.83,0,0,1,47.22,37.71A8,8,0,0,1,250.14,206.7Z"></path></svg>
                    <span>Testimonials</span>
                </NavLink>
                <a href="/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path></svg>
                    <span>Back Home</span>
                </a>
            </nav>
            <div className="app-content app" onClick={() => setNavOpen(false)}>
                <Outlet />
            </div>
        </div>
    )
}
