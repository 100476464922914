import React, { useState, useEffect } from 'react'
import * as Bytescale from '@bytescale/sdk'
import { toast } from 'react-toastify'
import './styles.css'

const uploadManager = new Bytescale.UploadManager({
    apiKey: 'public_FW25auSCvR4nArty47Bdi5ob5og9'
});

type Props = {
    onFileUploaded: (fileUrl: string) => void,
    name: string,
    label: string
}

export const UploadZone: React.FC<Props> = ({
    onFileUploaded,
    name,
    label
}) => {
    const [progress, setProgress] = useState<number>(0)
    const [error, setError]       = useState<{ message: string } | null>(null)
    const [fileInputKeyToResetInput, setFileInputKeyToResetInput] = useState<number>(123)

    let removeErrorTimeout: NodeJS.Timeout | null

    const handleFileUploaded = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target?.files?.length) {
            return
        }

        const file = e.target.files[0]

        try {
            const promise = uploadManager.upload({
                data: file,
                onProgress: (status) => setProgress(status.progress)
            })

            toast.promise(
                promise,
                {
                    pending: 'Uploading file...',
                    success: 'File uploaded successfully!',
                    error: 'Something went wrong uploading this file.'
                }
            );

            const { fileUrl } = await promise

            onFileUploaded(fileUrl)
            setFileInputKeyToResetInput(Date.now())
        } catch (err) {
            const msg = err?.message ?? 'Something went wrong uploading this file.'
            toast.error(msg)
            setError(err)
        }
    }

    useEffect(() => {
        if (error) {
            removeErrorTimeout = setTimeout(() => {
                setError(null)
            }, 5 * 1000) // 5 seconds
        } else if (removeErrorTimeout) {
            clearTimeout(removeErrorTimeout)
            removeErrorTimeout = null
        }
    }, [error])

    if (error) {
        return <p>{error.message}</p>
    }

    if (progress && progress < 100) {
        return <p>File uploading... {progress}%</p>
    }

    return (
        <label htmlFor={name}>
            {label}
            <input
                type="file"
                name={name}
                onChange={handleFileUploaded}
                key={fileInputKeyToResetInput}
            />
        </label>
    )
}
