import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useFormik, FieldArray } from 'formik'
import {
    Testimonial,
    getTestimonials, 
    createTestimonials
} from '../../requests/testimonials'
import { handleError } from '../../errors/handleError'
import { AdminContainer } from '../../components/AdminContainer'
import './styles.css'

type Props = {
}

export const Testimonials: React.FC<Props> = () => {
    const formik = useFormik({
        initialValues: {
            testimonials: []
        } as { testimonials: Testimonial[] },

        onSubmit: async (values) => {
            try {
                await createTestimonials(values.testimonials)
                toast.success('Testimonials saved!')
            } catch (err) {
                handleError(err, 'Whoops, something went wrong saving your testimonials. Please try again later.')
            }
        },

        onReset: () => {
            getTestimonials().then((res) => {
                formik.setFieldValue('testimonials', res.data)
            })
        }
    })

    useEffect(() => {
        getTestimonials().then((res) => {
            formik.setFieldValue('testimonials', res.data)
        })
    }, [])

    return (
        <AdminContainer title="Testimonials" formik={formik} onSubmit={formik.handleSubmit}>
            <FieldArray name="testimonials">
                {({ push, remove }) => (
                    <>
                        <button className="primary" type="button" onClick={() => push({ quote: '', name: ''})}>New Testimonial</button>
                        <div className="testimonials">
                            {
                                !formik.values.testimonials?.length && (
                                    <p>No testimonials to show.</p>
                                )
                            }
                            {formik.values.testimonials.map((testimonial, index) => (
                                <div key={index} className="testimonial">
                                    <label htmlFor={`testimonials.${index}.quote`}>
                                        Quote
                                        <textarea
                                            defaultValue={testimonial.quote}
                                            rows={5}
                                            required
                                            name={`testimonials.${index}.quote`}
                                            onChange={formik.handleChange}
                                            placeholder="Outpost builders were amazing"
                                        />
                                    </label>

                                    <label htmlFor={`testimonials.${index}.name`}>
                                        Client Name
                                        <input
                                            type="text"
                                            value={testimonial.name}
                                            required
                                            name={`testimonials.${index}.name`}
                                            onChange={formik.handleChange}
                                            placeholder="Scott Monroe, Wilmington NC"
                                        />
                                    </label>
                                    <button type="button" onClick={() => remove(index)}>Remove</button>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </FieldArray>
        </AdminContainer>
    )
}

