import * as React from "react"
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom"

// Auth
import { Login } from './pages/Login'

// Layout
import { AppLayout } from './layouts/App'

import { Photos } from './pages/Photos'
import { Testimonials } from './pages/Testimonials'


const router = createBrowserRouter([
  {
    path: "/app",
    element: <Login />
  },
  {
    path: "/app/dashboard",
    element: <AppLayout />,
    children: [
        // photos
        {
            path: "photos",
            element: <Photos />
        },

        // testimonials
        {
            path: "testimonials",
            element: <Testimonials />
        }
    ]
  }
])

export const Routes = () => {

    return (
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    )
}
