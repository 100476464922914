import React from 'react'
import { FormikContextType, FormikProvider } from 'formik'

type Props = {
    onSubmit: React.FormEventHandler
    formik: FormikContextType<any>
    children: React.ReactNode
    title: string
}

export const AdminContainer: React.FC<Props> = ({
    onSubmit,
    formik,
    children,
    title
}) => {
    return (
        <form className="admin-container" onSubmit={onSubmit}>
            <h2>{title}</h2>
            <FormikProvider value={formik}>
                <div className="content">
                    { children }
                </div>
            </FormikProvider>
            <div className="admin-actions">
                <button className="primary" type="submit" disabled={formik.isSubmitting}>
                    Save
                </button>
            </div>
        </form>
    )
}

