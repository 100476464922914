import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import {
    startEmailLogin,
    confirmEmailLogin,
    getSession
} from '../../requests/session'
import { handleError } from '../../errors/handleError'
import './styles.css'

export const Login: React.FC = () => {
    const [confirmingCode, setConfirmingCode] = useState(false)
    const [methodId, setMethodId] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem('ob-token')
        if (token) {
            getSession().then(() => {
                navigate('/app/dashboard/photos')
            })
        }
    }, [])

    const loginFormik = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: async (values) => {
            try {
                const res = await startEmailLogin(values)
                setMethodId(res.data.methodId)
                setConfirmingCode(true)
                toast.success('We sent you a code.')
            } catch (err) {
                handleError(err, 'Whoops, something went wrong logging you in. Please try again later.')
            }
        }
    })

    const confirmFormik = useFormik({
        initialValues: {
            code: ''
        },
        onSubmit: async (values) => {
            try {
                const res = await confirmEmailLogin({
                    code: values.code,
                    methodId
                })

                localStorage.setItem('ob-token', res.data.sessionJwt)

                navigate('/app/dashboard/photos')
                toast.success('Welcome!')
            } catch (err) {
                handleError(err, 'Whoops, something went wrong logging you in. Please try again later.')
            }
        }
    })

    if (!confirmingCode) {
        return (
            <div className="app login">
                <img src="/assets/logo.png" alt="Outpost Builders" />
                <h1>Log In</h1>
                <form onSubmit={loginFormik.handleSubmit}>
                    <input required type="email" name="email" value={loginFormik.values.email} placeholder="Email Address" onChange={loginFormik.handleChange} />

                    <div className="actions-footer">
                        <a href="/">
                            <button className="secondary" type="button" disabled={loginFormik.isSubmitting}>Go Back</button>
                        </a>
                        <button className="primary" type="submit" disabled={loginFormik.isSubmitting}>Submit</button>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div className="app login">
            <img src="/assets/logo.png" alt="Outpost Builders" />
            <h1>We sent you a code.</h1>
            <form onSubmit={confirmFormik.handleSubmit}>
                <input required type="text" name="code" value={confirmFormik.values.code} placeholder="Code" onChange={confirmFormik.handleChange}/>

                <div className="actions-footer">
                    <button className="primary" type="submit" disabled={confirmFormik.isSubmitting}>Submit</button>
                </div>
            </form>
        </div>
    )
}

